import "bootstrap/dist/css/bootstrap.min.css"
import "./src/components/cookit.min.css"
import "./src/components/navik-overlay-menu.min.css"
import "./src/components/style.css"


export const onInitialClientRender = () => {
  
}

