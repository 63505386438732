exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-challenges-index-js": () => import("./../../../src/pages/challenges/index.js" /* webpackChunkName: "component---src-pages-challenges-index-js" */),
  "component---src-pages-coin-staking-js": () => import("./../../../src/pages/coin/staking.js" /* webpackChunkName: "component---src-pages-coin-staking-js" */),
  "component---src-pages-coin-tokenomics-js": () => import("./../../../src/pages/coin/tokenomics.js" /* webpackChunkName: "component---src-pages-coin-tokenomics-js" */),
  "component---src-pages-games-extended-js": () => import("./../../../src/pages/games/extended.js" /* webpackChunkName: "component---src-pages-games-extended-js" */),
  "component---src-pages-games-originals-js": () => import("./../../../src/pages/games/originals.js" /* webpackChunkName: "component---src-pages-games-originals-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-presale-index-js": () => import("./../../../src/pages/presale/index.js" /* webpackChunkName: "component---src-pages-presale-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

